import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Select,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { chakra } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { encode } from '../utils/encode';
import axios from 'axios';
import { GatsbyImage as Image, getImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  topics: string;
}

export default function NewsletterPage() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [formSubmitted, setFormSubmitted] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "parentshop-logo-simple.png" }) {
        childImageSharp {
          gatsbyImageData(width: 300)
        }
      }
    }
  `);

  const image = getImage(data.logo.childImageSharp.gatsbyImageData);

  async function onSubmit({ firstName, lastName, email, topics }: FormData) {
    setIsLoading(true);
    const data = encode({
      'form-name': 'newsletter',
      firstName,
      lastName,
      email,
      topics,
    });
    axios
      .post('/', data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then(() => {
        setFormSubmitted(true);
      })
      .catch((err) => console.error(err.message))
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <Flex
      flexDir={'column'}
      justifyContent={'start'}
      w={'full'}
      alignItems={'center'}
      flexGrow={1}
      gap={3}
      pt={{
        base: 16,
        md: 32,
        lg: 48,
      }}
    >
      <Flex alignItems={'center'} gap={3}>
        <Image image={image} alt="parentshop logo" />
      </Flex>

      <Text>
        Sign up to our newsletter to receive the latest news and updates
      </Text>
      {formSubmitted ? (
        <Text>Thank you for signing up to our newsletter!</Text>
      ) : (
        <chakra.form
          w={{
            base: '100%',
            md: '50%',
            lg: '30%',
          }}
          onSubmit={handleSubmit(onSubmit)}
          name="newsletter"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="newsletter" />
          <Flex flexDir={'column'} w={'full'} gap={2} mt={8}>
            <FormControl>
              <FormLabel>First name</FormLabel>
              <Input
                type="text"
                placeholder="First name"
                {...register('firstName', { required: true })}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Last name</FormLabel>
              <Input
                type="text"
                placeholder="Last name"
                {...register('lastName', { required: true })}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                placeholder="Email address"
                {...register('email', { required: true })}
              />
            </FormControl>

            <FormControl id="category">
              <FormLabel>Category</FormLabel>
              <Select
                placeholder="Select newsletter category"
                cursor="pointer"
                {...register('topics', { required: true })}
              >
                <option value={'school-leaders-teachers'}>
                  School Leaders & Teachers
                </option>
                <option value={'child-family-specialists'}>
                  Child & Family Specialists
                </option>
                <option value={'early-years-educators'}>
                  Early Years Educators
                </option>
                <option value={'parents'}>Parents</option>
              </Select>
            </FormControl>
            <Button
              colorScheme={'secondary'}
              type="submit"
              isLoading={isLoading}
            >
              Sign up
            </Button>
          </Flex>
        </chakra.form>
      )}
    </Flex>
  );
}
